import React, {ReactNode, useState} from "react";

import "./OffCanvasLayout.scss"


type OffCanvasLayoutProps = {
    /** Inhalt, welcher in der Sidebar dargestellt werden soll. */
    sidebar: ReactNode,
    /** Inhalt, welcher in der Content Area dargestellt werden soll. */
    main: ReactNode
    /** Footer, welcher sticky unterhalb der Content Area dargestellt werden soll. */
    footer: ReactNode
}

/**
 * Komponente welche das "Off-canvas" Layout aus Spectre abbildet.
 * (siehe https://picturepan2.github.io/spectre/experimentals/off-canvas.html)
 */
function OffCanvasLayout(props: OffCanvasLayoutProps) {

    const [menuOpen, setMenuOpen] = useState(false);

    function onMenuIconClick() {
        setMenuOpen(true)
    }

    function onContentAreaClick() {
        setMenuOpen(false)
    }

    return (
        <div className="off-canvas off-canvas-sidebar-show off-canvas-layout">

            <button className="off-canvas-toggle btn btn-primary btn-action off-canvas-layout-toggle"
                    onClick={onMenuIconClick}>
                <i className="las la-bars la-lg"/>
            </button>

            <div className={"off-canvas-sidebar off-canvas-layout-sidebar " + (menuOpen ? "active" : "")}>
                {props.sidebar}
            </div>

            <button className="off-canvas-overlay" onClick={onContentAreaClick}/>

            <div className="off-canvas-content off-canvas-layout-content">
                <div className="off-canvas-layout-main">
                    {props.main}
                </div>
                <div className="off-canvas-layout-footer">
                    {props.footer}
                </div>
            </div>

        </div>
    );
}

export default OffCanvasLayout;
