import React, {ReactNode} from "react";
import {VeranstaltungMitAblageort} from "../model/routing";
import CalendarChip from "../shared/CalendarChip";
import Ergebnislisten from "./Ergebnislisten";
import Rennliste from "./Rennliste";

import "./Veranstaltungsinfo.scss"

type VeranstaltungsinfoProps = {
    /** Die darzustellende Veranstaltung. */
    veranstaltung: VeranstaltungMitAblageort
}

/**
 * Komponente welche Informationen zur Veranstaltung anzeigt.
 */
function Veranstaltungsinfo(props: VeranstaltungsinfoProps) {
    const veranstaltung = props.veranstaltung;

    const links = veranstaltungsLinks(veranstaltung);

    return (
        <div>
            <h3 className="veranstaltungsinfo-ueberschrift">
                {veranstaltung.ueberschrift1}
                <br/>
                {veranstaltung.ueberschrift2}
                <br/>
                {veranstaltung.ueberschrift3}
            </h3>

            <div>
                <CalendarChip von={veranstaltung.beginn} bis={veranstaltung.ende}/>
            </div>

            <div className="veranstaltungsinfo-links">
                {links}
            </div>
        </div>
    );
}

function veranstaltungsLinks(veranstaltung: VeranstaltungMitAblageort): ReactNode {
    const left = linksZuRennen(veranstaltung);
    const right = linksZuErgebnislisten(veranstaltung);
    return renderTwoColumnLayout(left, right);
}

function linksZuRennen(veranstaltung: VeranstaltungMitAblageort): ReactNode {
    const rennenMitAblageort = veranstaltung.rennen.map(r => {
        return {...r, vereinsordner: veranstaltung.vereinsordner}
    });

    const rennliste = rennenMitAblageort.length === 0 ?
        <p className="text-warning">Für die Veranstaltung sind noch keine Rennen definiert.</p>
        :
        <Rennliste rennen={rennenMitAblageort}/>;

    return <>
        <div className="divider" data-content="Alle Rennen der Veranstaltung"/>
        {rennliste}
    </>;
}

function linksZuErgebnislisten(veranstaltung: VeranstaltungMitAblageort): ReactNode {
    // Ergebnislisten sind in WinCanoe 9.0 noch nicht vorhanden.
    // In diesem Fall soll nicht angezeigt werden, dass noch keine Ergebnislisten vorhanden wären.
    if (veranstaltung.ergebnislisten === undefined) {
        return <></>
    }

    const ergebnislistenMitAblageort = veranstaltung.ergebnislisten.map(e => {
        return {...e, vereinsordner: veranstaltung.vereinsordner}
    });

    const ergebnislisten = ergebnislistenMitAblageort.length === 0 ?
        <p>Für die Veranstaltung liegen noch keine Ergebnislisten vor.</p>
        :
        <Ergebnislisten ergebnislisten={ergebnislistenMitAblageort}/>;

    return <>
        <div className="divider" data-content="Ergebnislisten"/>
        {ergebnislisten}
    </>;
}

function renderTwoColumnLayout(left: ReactNode, right: ReactNode): ReactNode {
    return (
        <div className="container">
            <div className="columns">
                <div className="column col-6 col-lg-12">
                    {left}
                </div>
                <div className="column col-6 col-lg-12">
                    {right}
                </div>
            </div>
        </div>
    );
}

export default Veranstaltungsinfo;
