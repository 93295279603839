import React from "react";
import {Link} from "react-router-dom";
import {idFuerVeranstaltung, VeranstaltungMitAblageort} from "../model/routing";
import CalendarChip from "../shared/CalendarChip";

import "./Veranstaltungsliste.scss"

type VeranstaltungslisteProps = {
    /** Liste der darzustellenden Veranstaltungen. */
    veranstaltungen: VeranstaltungMitAblageort[]
}

/**
 * Komponente welche eine Liste an Veranstaltungen auflistet.
 *
 * Wird eine Veranstaltung ausgewählt, wird die entsprechende Route aktiviert.
 */
function Veranstaltungsliste(props: VeranstaltungslisteProps) {
    if (props.veranstaltungen.length === 0) {
        return <p className="text-warning">Es sind noch keine Veranstaltungen definiert.</p>
    }

    const cards = sortiereNachDatum(props.veranstaltungen).map(veranstaltung => {
        const id = idFuerVeranstaltung(veranstaltung);
        const href = '/' + id;

        return (
            <Link key={id} to={href}>
                <div className="card veranstaltungsliste-card">

                    <div className="card-header">
                        <div className="card-title h5 veranstaltungsliste-name">
                            {veranstaltung.name}
                        </div>

                        <div className="card-subtitle veranstaltungsliste-ueberschrift">
                            {veranstaltung.ueberschrift1}
                        </div>
                    </div>

                    <div className="card-body">
                        <CalendarChip von={veranstaltung.beginn} bis={veranstaltung.ende}/>
                    </div>
                </div>
            </Link>
        );
    });

    return <div className="veranstaltungsliste-card-container"> {cards} </div>
}

function sortiereNachDatum(veranstaltungen: VeranstaltungMitAblageort[]): VeranstaltungMitAblageort[] {
    // ältere Veranstaltungen sollen weiter unten angezeigt werden
    return veranstaltungen.sort((a, b) => {
        return new Date(b.beginn).getTime() - new Date(a.beginn).getTime();
    });
}

export default Veranstaltungsliste;
