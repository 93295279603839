import React from "react";
import {ErgebnislisteMitAblageort} from "../model/routing";
import {safeJoin} from "../shared/http";

type ErgebnislistenProps = {
    /** Liste der Ergebnislisten. */
    ergebnislisten: ErgebnislisteMitAblageort[]
}

/**
 * Komponente welche Ergebnislisten zum Download verlinkt.
 */
function Ergebnislisten(props: ErgebnislistenProps) {

    const items = sortiereErgebnislisten(props.ergebnislisten).map(liste => {
        const href = safeJoin(liste.vereinsordner, liste.dateiname);

        return (
            <li key={href} className="nav-item">
                <a href={href} target="_blank" rel="noopener noreferrer">
                    <i className="las la-file-pdf la-lg mx-2"/>
                    {liste.name}
                </a>
            </li>
        );
    });

    return (
        <ul className="nav">
            {items}
        </ul>
    );
}

function sortiereErgebnislisten(listen: ErgebnislisteMitAblageort[]): ErgebnislisteMitAblageort[] {
    return listen.sort((a, b) => {
        return a.name.localeCompare(b.name);
    });
}

export default Ergebnislisten;
