import React from "react";
import {Link, Route, useRouteMatch} from "react-router-dom";
import {idFuerRennen, RennenMitAblageort} from "../model/routing";

type RennlisteProps = {
    /** Liste der darzustellenden Rennen. */
    rennen: RennenMitAblageort[]
}

/**
 * Komponente welche eine Liste an Rennen auflistet.
 *
 * Wird ein Rennen ausgewählt, wird die entsprechende Route aktiviert.
 */
function Rennliste(props: RennlisteProps) {

    const {url} = useRouteMatch();

    const menuItems = sortiereRennen(props.rennen).map(r => {
        const id = idFuerRennen(r);
        const href = url + '/' + id;

        return (
            <Route key={id} path={href} children={({match}) => (
                <li className={'nav-item ' + (match ? 'active' : '')}>
                    <Link to={href}>{r.name}</Link>
                </li>
            )}/>
        );
    });

    return (
        <ul className="nav">
            {menuItems}
        </ul>
    );
}

function sortiereRennen(rennen: RennenMitAblageort[]): RennenMitAblageort[] {
    return rennen.sort((a, b) => {

        // WinCanoe erzeugt, für die sich aktuell auf der Strecke befindenden Sportler, ein Rennen, welches mit dem Namen "Live" beginnt.
        // Dieses Rennen soll immer an oberster Stelle stehen.
        // -> Wenn einer der beiden verglichenen Rennen live ist, dann wird danach vorrangig sortiert.
        //    (sind beide live oder beide nicht live wird alphabetisch sortiert)
        if (isLive(a) !== isLive(b)) {
            return isLive(a) ? -1 : 1;
        }

        // Alle weiteren Rennen werden nach der Rennnummer sortiert.
        // Der Dateiname z.B. "11.json" entspricht der Rennnummer in späteren Versionen soll die Rennnummer oder der Startzeitpunkt
        // des Rennens hierfür zusätzlich übermittelt werden.
        //
        // Hinweis: Sollte ein Dateiname nicht als Number interpretiert werden können wird mit NaN weitergearbeitet.
        // Diese Rennen werden dann ans Ende der Liste gestellt.
        const rennnummerA = Number(a.dateiname.slice(0, -5))  // .json -> 5 characters
        const rennnummerB = Number(b.dateiname.slice(0, -5))
        return rennnummerA - rennnummerB;
    });
}

function isLive(rennen: RennenMitAblageort): boolean {
    return rennen.name.toLowerCase().startsWith('live');
}

export default Rennliste;
