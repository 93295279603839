import React from "react";
import {HashRouter, Route, Switch} from "react-router-dom";
import Veranstaltungsseite from "./veranstaltung/Veranstaltungsseite";
import Veranstaltungsuebersichtsseite from "./veranstaltungsuebersicht/Veranstaltungsuebersichtsseite";

type AppProps = {
    /** Namen der Vereinsordner, in welchen nach Veranstaltungen gesucht wird. */
    vereinsordner: string[]
}

/**
 * Hauptkomponente der Live-Anzeige.
 */
function App(props: AppProps) {
    return (
        // Es wird der HashRouter verwendet, um möglichst wenig Anforderungen an den ausliefernden Webserver zu stellen.
        // Die Nutzung von BrowserRouter würde erfordern, dass jede Route mit der index.html beantwortet wird.
        <HashRouter>
            <Switch>
                <Route exact path="/">
                    <Veranstaltungsuebersichtsseite vereinsordner={props.vereinsordner}/>
                </Route>
                <Route path="/:id_veranstaltung">
                    <Veranstaltungsseite/>
                </Route>
            </Switch>
        </HashRouter>
    );
}

export default App;
