import React from "react";

type CalendarChipProps = {
    /** Von-Datum als ISO string. */
    von: string
    /** Bis-Datum als ISO string. */
    bis: string
}

/**
 * Komponente welche ein "Von-" und "Bis-Datum" mit einem Kalender Icon darstellt.
 */
function CalendarChip(props: CalendarChipProps) {
    return (
        <span className="chip">
         <i className="las la-calendar la-lg mr-1"/>
            {isoToGermanDateFormat(props.von)} bis {isoToGermanDateFormat(props.bis)}
        </span>
    );
}

function isoToGermanDateFormat(date: string) {
    return new Date(date).toLocaleDateString('de-DE');
}

export default CalendarChip;
