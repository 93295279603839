import React, {useEffect, useState} from "react";
import {VeranstaltungMitAblageort} from "../model/routing";
import {Veranstaltung} from "../model/veranstaltung";
import {Veranstaltungsdateinamen} from "../model/veranstaltungen";
import AppInfo from "../shared/AppInfo";
import {requestJson} from "../shared/http";
import LoadingResource from "../shared/LoadingResource";
import {notNull} from "../shared/typescript";
import Veranstaltungsliste from "./Veranstaltungsliste";


type VeranstaltungsuebersichtsseiteProps = {
    /** Namen der Vereinsordner, in welchen nach Veranstaltungen gesucht wird. */
    vereinsordner: string[]
}

/**
 * Seite welche eine Übersicht über die Veranstaltungen aller Vereine bietet.
 */
function Veranstaltungsuebersichtsseite(props: VeranstaltungsuebersichtsseiteProps) {

    const [ladevorgangFuerVeranstaltungen, setLadevorgangFuerVeranstaltungen] = useState<Promise<any> | undefined>(undefined);
    const [veranstaltungen, setVeranstaltungen] = useState<VeranstaltungMitAblageort[] | undefined>(undefined);

    useEffect(() => {
        setLadevorgangFuerVeranstaltungen(
            ladeVeranstaltungen(props.vereinsordner).then(v => setVeranstaltungen(v))
        )
    }, [props.vereinsordner]);

    return (
        // Margin m-2 wird auch im Veranstaltungsmenu genutzt, die AppInfo "springt" somit nicht. */}
        <div className="m-2">
            <AppInfo/>
            <LoadingResource promise={ladevorgangFuerVeranstaltungen}
                             errorMessage="Die Liste der Veranstaltungen konnte nicht geladen werden.">
                {veranstaltungen && <Veranstaltungsliste veranstaltungen={veranstaltungen}/>}
            </LoadingResource>
        </div>
    );
}

/**
 * Lädt die Veranstaltungen aller Vereine (Promise rejected nie, gibt [] zurück).
 */
async function ladeVeranstaltungen(vereinsordner: string[]): Promise<VeranstaltungMitAblageort[]> {
    // Alle Vereinsordner sollen parallel ausgelesen werden.
    const promises = Promise.all(
        vereinsordner.map(v => ladeVeranstaltungenFuerVerein(v))
    );

    return (await promises).flat(1);
}

/**
 * Lädt die Veranstaltungen eines Vereins (Promise rejected nie, gibt [] zurück).
 */
async function ladeVeranstaltungenFuerVerein(vereinsordner: string): Promise<VeranstaltungMitAblageort[]> {
    // In jedem Vereinsordner muss eine vom Administrator verwaltete Datei "veranstaltungen.json" vorliegen.
    // Diese Datei enthält die, in Bezug zum Vereinsordner, relativen Pfade zu den Veranstaltungen.
    let veranstaltungsdateinamen: Veranstaltungsdateinamen = [];
    try {
        veranstaltungsdateinamen = await requestJson(vereinsordner, 'veranstaltungen.json')
    } catch (e) {
        console.warn("Datei 'veranstaltungen.json' aus dem Vereinsordner '%s' konnte nicht geladen werden: %s", vereinsordner, e.message)
    }

    // Die einzelnen Veranstaltungen sollen ebenfalls parallel geladen werden.
    const promises = Promise.all(
        veranstaltungsdateinamen.map(dateiname => ladeVeranstaltung(vereinsordner, dateiname))
    );

    // Konnte eine Veranstaltung nicht geladen werden wurde null zurückgegeben -> null values verwerfen.
    return (await promises).filter(notNull);
}

/**
 * Lädt die Details zu einer Veranstaltung (Promise rejected nie, gibt null zurück).
 */
async function ladeVeranstaltung(vereinsordner: string, dateiname: string): Promise<VeranstaltungMitAblageort | null> {
    let veranstaltung: Veranstaltung;
    try {
        veranstaltung = await requestJson(vereinsordner, dateiname);
    } catch (e) {
        console.warn("Datei '%s' aus dem Vereinsordner '%s' konnte nicht geladen werden: %s", dateiname, vereinsordner, e.message)
        return null;
    }

    return {
        ...veranstaltung,
        vereinsordner: vereinsordner,
        dateiname: dateiname
    }
}

export default Veranstaltungsuebersichtsseite;
