import React, {ReactNode, useEffect, useState} from "react";
import {Link, Route, Switch, useParams} from "react-router-dom";
import {ablageortAusVeranstaltungsId, idFuerVeranstaltung, VeranstaltungMitAblageort} from "../model/routing";
import {Veranstaltung} from "../model/veranstaltung";
import {requestJson} from "../shared/http";
import LoadingResource from "../shared/LoadingResource";
import OffCanvasLayout from "../shared/OffCanvasLayout";
import Logos from "./Logos";
import Renninfo from "./rennen/Renninfo";
import Veranstaltungsinfo from "./Veranstaltungsinfo";
import Veranstaltungsmenu from "./Veranstaltungsmenu";

import "./Veranstaltungsseite.scss"

/**
 * Seite welche Informationen und Ergebnisse einer Veranstaltung anzeigt.
 *
 * Die Veranstaltung wird hierbei immer wieder neu vom Server abgefragt und aktualisiert.
 * Änderungen an den Rennen sind zwar eher selten, aber möglich z.B. wird nach dem abgeschlossenen Vorlauf das Rennen für das
 * Finale angelegt. Eine automatische Aktualisierung macht es möglich, die Live-Anzeige auf einem Bildschirm am Veranstaltungsort
 * anzuzeigen.
 */
function Veranstaltungsseite() {

    const refreshIntervalInMilliSeconds = 10000;

    const {id_veranstaltung} = useParams();
    const ablageort = ablageortAusVeranstaltungsId(id_veranstaltung || '');

    const [ladevorgangFuerVeranstaltung, setLadevorgangFuerVeranstaltung] = useState<Promise<any> | undefined>(undefined);
    const [veranstaltung, setVeranstaltung] = useState<VeranstaltungMitAblageort | undefined>(undefined);

    useEffect(() => {
        setLadevorgangFuerVeranstaltung(
            requestJson<Veranstaltung>(ablageort.vereinsordner, ablageort.dateiname)
                .then(v => setVeranstaltung({...v, ...ablageort}))
        )
        // Neue Instanz von "ablageort" darf nicht zum Neuladen führen.
        // eslint-disable-next-line
    }, [id_veranstaltung]);

    useEffect(() => {
        const timer = setInterval(() => {
            // Im Gegensatz zu den Ergebnissen verwirrt es den User wahrscheinlich mehr, als dass es ihm hilft, wenn eine
            // Fehlermeldung für das Aktualisieren der Veranstaltung angezeigt werden würde -> keine zweite LoadingResource.
            requestJson<Veranstaltung>(ablageort.vereinsordner, ablageort.dateiname)
                .then(v => setVeranstaltung({...v, ...ablageort}))
        }, refreshIntervalInMilliSeconds);

        return () => clearTimeout(timer);
        // Neue Instanz von "ablageort" darf nicht zum Neuladen führen.
        // eslint-disable-next-line
    }, [id_veranstaltung]);

    return (
        <LoadingResource promise={ladevorgangFuerVeranstaltung}
                         errorMessage="Die Veranstaltung konnte nicht geladen werden.">
            {veranstaltung && renderPage(veranstaltung)}
        </LoadingResource>
    );
}

function renderPage(veranstaltung: VeranstaltungMitAblageort): ReactNode {

    const sidebar = <Veranstaltungsmenu veranstaltung={veranstaltung}/>;

    const hrefFuerVeranstaltung = '/' + idFuerVeranstaltung(veranstaltung);

    const main = <main>
        <h2 className="veranstaltungsseite-name">
            <Link to={hrefFuerVeranstaltung}> {veranstaltung.name} </Link>
        </h2>

        <Switch>
            <Route path="/:id_veranstaltung/:id_rennen">
                <Renninfo/>
            </Route>
            <Route path="/:id_veranstaltung">
                <Veranstaltungsinfo veranstaltung={veranstaltung}/>
            </Route>
        </Switch>
    </main>;

    const logosMitAblageort = veranstaltung.logos.map(l => {
        return {...l, vereinsordner: veranstaltung.vereinsordner};
    });

    const footer = <footer className="mt-2">
        <Logos logos={logosMitAblageort}/>
    </footer>;

    return <OffCanvasLayout sidebar={sidebar}
                            main={main}
                            footer={footer}/>
}

export default Veranstaltungsseite;
