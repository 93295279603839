import React, {ReactNode, useEffect, useState} from "react";

type LoadingResourceProps = {
    /**
     * Promise, welches die ladende Ressource darstellt.
     * Ist noch kein Promise gesetzt wird bereits der Ladeindikator angezeigt.
     */
    promise?: Promise<any>,
    /** Wirft das Promise einen Fehler wird diese Fehlermeldung angezeigt. */
    errorMessage: string
    /** React Children welche im Positivfall angezeigt werden. */
    children: ReactNode
}

enum ResourceState {
    Loading,
    Success,
    Error,
}

/**
 * Komponente welche einen Ladeindikator anzeigt bis das übergebene Promise returned.
 * Wirft das Promise einen Fehler wird die übergebene Fehlermeldung angezeigt.
 * Im Positivfall werden die Children der Komponente angezeigt.
 */
function LoadingResource(props: LoadingResourceProps) {

    const [resourceState, setResourceState] = useState(ResourceState.Loading);

    useEffect(() => {
        if (props.promise) {
            props.promise
                .then(_ => setResourceState(ResourceState.Success))
                .catch(_ => setResourceState(ResourceState.Error))
        }
    }, [props.promise]);

    switch (resourceState) {
        case ResourceState.Loading:
            return <div className="loading loading-lg"/>;
        case ResourceState.Success:
            return <div>{props.children}</div>;
        case ResourceState.Error:
            return <div className="toast toast-error">{props.errorMessage}</div>
    }
}

export default LoadingResource;
