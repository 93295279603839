// This must be the first line in index.tsx!
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './assets/css/index.scss'

(() => {

    const appContainer = document.getElementById('live-anzeige');
    if (appContainer === null) {
        console.error("DIV mit der ID 'live-anzeige' nicht gefunden");
        return
    }

    const vereinsordnerAsString = appContainer.getAttribute('data-vereinsordner');
    if (vereinsordnerAsString === null) {
        console.error("DIV mit der ID 'live-anzeige' enthält kein Attribut 'data-vereinsordner'");
        return
    }

    const vereinsordner = vereinsordnerAsString.split(',').map(s => s.trim()).filter(s => s.length !== 0);

    ReactDOM.render(<App vereinsordner={vereinsordner}/>, appContainer);
})();
