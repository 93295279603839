import React, {ReactNode, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Renndetails} from "../../model/renndetails";
import {ablageortAusRennId} from "../../model/routing";
import {requestJson} from "../../shared/http";
import LoadingResource from "../../shared/LoadingResource";
import Ergebnislisten from "../Ergebnislisten";
import Rennergebnisse from "./Rennergebnisse";

import "./Renninfo.scss"

/**
 * Komponente welche Informationen zu einem Rennen anzeigt.
 *
 * Die Rennergebnisse werden hierbei immer wieder neu vom Server abgefragt und somit "live" dargestellt.
 */
function Renninfo() {

    const refreshIntervalInMilliSeconds = 3000;

    const {id_rennen} = useParams();
    const ablageort = ablageortAusRennId(id_rennen || '');

    const [ladevorgangFuerRenndetailsInitial, setLadevorgangRenndetailsInitial] = useState<Promise<any> | undefined>(undefined);
    const [ladevorgangFuerRenndetailsUpdate, setLadevorgangRenndetailsUpdate] = useState<Promise<any>>(Promise.resolve());
    const [renndetails, setRenndetails] = useState<Renndetails | undefined>(undefined);

    useEffect(() => {
        setLadevorgangRenndetailsInitial(
            requestJson<Renndetails>(ablageort.vereinsordner, ablageort.dateiname)
                .then(r => setRenndetails({...r, ...ablageort}))
        );
        // Neue Instanz von "ablageort" darf nicht zum Neuladen führen.
        // eslint-disable-next-line
    }, [id_rennen]);

    useEffect(() => {
        const timer = setInterval(() => {
            setLadevorgangRenndetailsUpdate(
                requestJson<Renndetails>(ablageort.vereinsordner, ablageort.dateiname)
                    .then(r => setRenndetails({...r, ...ablageort}))
            );
        }, refreshIntervalInMilliSeconds);

        return () => clearTimeout(timer);
        // Neue Instanz von "ablageort" darf nicht zum Neuladen führen.
        // eslint-disable-next-line
    }, [id_rennen]);

    return (
        <LoadingResource promise={ladevorgangFuerRenndetailsInitial}
                         errorMessage="Die Ergebnisse des Rennens konnten nicht geladen werden.">

            {renndetails && ergebnisse(renndetails, ablageort.vereinsordner)}

            <LoadingResource promise={ladevorgangFuerRenndetailsUpdate}
                             errorMessage="Die Ergebnisse des Rennens können nicht aktualisiert werden.">
                {/* Diese LoadingResource stellt keinen Inhalt dar. */}
                {/* Sie wird nur genutzt um einen Ladeindikator und ggf. eine Fehlermeldung anzuzeigen. */}
                {/* Platzierung erfolgt unterhalb der Rennergebnisse um diese nicht "springen" zu lassen. */}
            </LoadingResource>

        </LoadingResource>
    );
}

function ergebnisse(renndetails: Renndetails, vereinsordner: string) {
    const ergebnislisten = linksZuErgebnislisten(renndetails, vereinsordner);

    return <>
        <h3>{renndetails.name}</h3>
        <Rennergebnisse ergebnisse={renndetails.ergebnisse}/>
        {ergebnislisten}
    </>
}

function linksZuErgebnislisten(renndetails: Renndetails, vereinsordner: string): ReactNode {
    // Ergebnislisten sind in WinCanoe 9.0 noch nicht vorhanden.
    // In diesem Fall soll nicht angezeigt werden, dass noch keine Ergebnislisten vorhanden wären.
    if (renndetails.ergebnislisten === undefined) {
        return <></>
    }

    const ergebnislistenMitAblageort = renndetails.ergebnislisten.map(e => {
        return {...e, vereinsordner: vereinsordner}
    });

    const ergebnislisten = ergebnislistenMitAblageort.length === 0 ?
        <p>Für das Rennen liegen noch keine Ergebnislisten vor.</p>
        :
        <Ergebnislisten ergebnislisten={ergebnislistenMitAblageort}/>;

    return <div className="renninfo-ergebnislisten">
        <div className="divider" data-content="Ergebnislisten"/>
        {ergebnislisten}
    </div>
}

export default Renninfo;
