import axios from "axios";
import * as path from "path";

/**
 * Führt einen HTTP GET Request aus, um den Inhalt einer JSON Datei aus dem Vereinsordner zu laden.
 *
 * Hierbei wird der Server mittels 'no-cache' Header angewiesen die Resource immer erneut auszulesen.
 *
 * Stellt sicher, dass der angegebene Dateiname den ".json" Suffix trägt.
 * Außerdem wird das sog. "directory traversal" verhindert, indem z.B. über ".." der Vereinsordner verlassen wird.
 * In beiden Fällen wir der Promise rejected.
 *
 * @param vereinsordner Vereinsordner, welcher als Basispfad verwendet wird
 * @param dateiname relativer Pfad zur JSON Datei innerhalb des Vereinsordners
 */
export async function requestJson<T>(vereinsordner: string, dateiname: string): Promise<T> {
    const normalizedAbsolutePath = safeJoin(vereinsordner, dateiname);

    if (!normalizedAbsolutePath.endsWith('.json')) {
        throw new Error(`Der Pfad '${normalizedAbsolutePath}' endet nicht mit '.json'.`);
    }

    const requestConfig = {
        headers: {
            'Cache-Control': 'no-cache'
        }
    };

    return (await axios.get(normalizedAbsolutePath, requestConfig)).data;
}

/**
 * Gibt den absoluten Pfad der Datei innerhalb des Vereinsordners zurück.
 *
 * Verhindert das sog. "directory traversal", indem z.B. über ".." der Vereinsordner verlassen wird.
 *
 * @param vereinsordner Vereinsordner, welcher als Basispfad verwendet wird
 * @param dateiname relativer Pfad zur Datei innerhalb des Vereinsordners
 * @throws wenn der Vereinsordner verlassen wurde
 */
export function safeJoin(vereinsordner: string, dateiname: string): string {
    const absolutePath = path.join(vereinsordner, dateiname)

    // normalize() löst ".." und "." Angaben auf
    const normalizedAbsolutePath = path.normalize(absolutePath)

    if (!normalizedAbsolutePath.startsWith(vereinsordner + '/')) {
        throw new Error(`Der Pfad '${normalizedAbsolutePath}' beginnt nicht im Ordner '${vereinsordner}'.`);
    }

    return normalizedAbsolutePath;
}