import React, {ReactNode} from "react";
import {sprintf} from "sprintf-js";
import {Ergebniszeile} from "../../model/renndetails";

import "./Rennergebnisse.scss"

type RennergebnisseProps = {
    /** Liste aller Ergebnisse aus dem Rennen. */
    ergebnisse: Ergebniszeile[]
}

/**
 * Komponente welche die Ergebnisse eines Rennens in tabellarischer Form darstellt.
 */
function Rennergebnisse(props: RennergebnisseProps) {

    const header = tableHeader();
    const rows = sortiereNachPlatzierung(props.ergebnisse).map(tableRow);

    return (
        <>
            <table className="table table-striped rennergebnisse-table">
                <thead>{header}</thead>
                <tbody>{rows}</tbody>
            </table>
        </>
    );
}

function sortiereNachPlatzierung(ergebnisse: Ergebniszeile[]): Ergebniszeile[] {
    return ergebnisse.sort((a, b) => {
        // Ist keine Platzierung vorhanden, soll der Sportler ganz unten angezeigt werden.
        return (a.platzierung || Number.MAX_VALUE) - (b.platzierung || Number.MAX_VALUE);
    });
}

function tableHeader(): ReactNode {
    // Ist wenig Platz vorhanden wird statt dem <span> das Attribut "data-abkuerzung" angezeigt.
    // In der mobilen Ansicht wird statt dem <span> das Attribut "data-mobile-label" auf dem <td> Tag angezeigt.
    return <tr>
        <th data-abkuerzung="Platz">
            <span>Platzierung</span>
        </th>
        <th data-abkuerzung="StNr">
            <span>Startnummer</span>
        </th>
        <th data-abkuerzung="Sportler">
            <span>Sportler / Verein</span>
        </th>
        <th data-abkuerzung="Lauf 1">
            <span>Lauf 1</span>
        </th>
        <th data-abkuerzung="Lauf 2">
            <span>Lauf 2</span>
        </th>
        <th data-abkuerzung="Gesamt">
            <span>Gesamt / Abstand</span>
        </th>
    </tr>
}

function tableRow(ergebnis: Ergebniszeile): ReactNode {
    return <tr key={ergebnis.startnummer}>
        <td data-mobile-label="Platz ">{ergebnis.platzierung}</td>
        <td data-mobile-label="Startnummer ">{ergebnis.startnummer}</td>
        <td data-mobile-label="">
            <div>
                {ergebnis.namen.map(name => <div key={name}>{name}</div>)}
            </div>
            <div className="rennergebnisse-verein">{ergebnis.verein}</div>
        </td>
        <td data-mobile-label="Lauf 1">
            <pre>
            {formatiereLauf(
                ergebnis.lauf1InSekunden,
                ergebnis.strafsekundenAusLauf1,
                ergebnis.begruendungKeineZeitAusLauf1
            )}
            </pre>
        </td>
        <td data-mobile-label="Lauf 2">
            <pre>
            {formatiereLauf(
                ergebnis.lauf2InSekunden,
                ergebnis.strafsekundenAusLauf2,
                ergebnis.begruendungKeineZeitAusLauf2
            )}
            </pre>
        </td>
        <td data-mobile-label="Gesamt / Abstand">
            <pre>
                {formatiereGesamtzeit(ergebnis.gesamtZeitInSekunden)}

                <div className="rennergebnisse-abstand">
                {formatiereAbstandZumFuehrenden(ergebnis.platzierung, ergebnis.abstandInSekunden)}
                </div>
            </pre>
        </td>
    </tr>
}

function formatiereLauf(fahrzeitInSekunden: number | null, strafsekunden: number | null, begruendungKeineZeit: string |
    null): string {
    if (begruendungKeineZeit != null) {
        return begruendungKeineZeit
    }

    // noinspection GrazieInspection
    if (fahrzeitInSekunden != null && strafsekunden != null) {
        // Spalten basierte Ausgabe z.B. "103,11 +   2 = 105,11"
        return sprintf('%6.2f + %3i = %6.2f', fahrzeitInSekunden, strafsekunden, fahrzeitInSekunden + strafsekunden)
    }

    return '-'
}

function formatiereGesamtzeit(gesamtZeitInSekunden: number | null) {
    if (gesamtZeitInSekunden != null) {
        return sprintf(' %6.2f', gesamtZeitInSekunden)
    }

    return '-'
}

function formatiereAbstandZumFuehrenden(platzierung: number | null, abstand: number | null) {
    if (platzierung === 1) {
        // beim Führenden selbst wird kein Abstand angezeigt
        return ''
    }

    if (!abstand) {
        // hat der Sportler keine Zeit wird kein Abstand angezeigt
        return ''
    }

    return sprintf('-%6.2f', abstand)
}

export default Rennergebnisse;
