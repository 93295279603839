import React from "react";
import {Link} from "react-router-dom";
import packageJson from '../../package.json';

import "./AppInfo.scss"

/**
 * Komponente welche den Namen der App sowie die Versionsnummer anzeigt.
 */
function AppInfo() {

    const appName = 'Live-Anzeige';
    const appVersion = packageJson.version;

    return <div className="app-info-container">

        <h1 className="app-info-name">
            <Link to="/">{appName}</Link>
        </h1>

        <span className="app-info-version" title="Softwareversion">
            {appVersion}
        </span>

    </div>
}

export default AppInfo;
