import {Ergebnisliste} from "./ergebnisliste";
import {Logo, Rennen, Veranstaltung} from "./veranstaltung";

/**
 * Kann genutzt werden um ein Model zu erweitern, indem Informationen zum Ablageort der Quelldatei ergänzt werden.
 */
export interface Ablageort {
    /** Name des Vereinsordners, in welchem die Datei abgelegt ist. */
    vereinsordner: string
    /**
     * Dateiname, welcher als relativer Pfad, in Bezug zum Vereinsordner, interpretiert wird.
     */
    dateiname: string
}

export type VeranstaltungMitAblageort = Veranstaltung & Ablageort;
export type RennenMitAblageort = Rennen & Ablageort;
export type LogoMitAblageort = Logo & Ablageort;
export type ErgebnislisteMitAblageort = Ergebnisliste & Ablageort;

// Die für das Routing benötigten IDs sollen primär URL-Safe und reversible sein.
// Nachdem die Pfade zu den JSON Dateien immer eindeutig sind können diese als Basis für IDs dienen.
// Hierbei werden mögliche Slashes (/), welche durch Unterordner entstehen mit einem Plus (+) ersetzt.
// Um die URLs etwas leserlicher zu gestalten wird noch die Dateiextension (.json) aus den Dateinamen entfernt.

/**
 * Gibt für die Veranstaltung eine ID zurück, welche innerhalb einer URL benutzt werden kann.
 */
export function idFuerVeranstaltung(veranstaltung: VeranstaltungMitAblageort): string {
    return idFuerAblageort(veranstaltung);
}

/**
 * Ermittelt den Ablageort der Veranstaltung auf Basis der ID.
 */
export function ablageortAusVeranstaltungsId(id: string): Ablageort {
    return ablageortAusId(id);
}

/**
 * Gibt für das Rennen eine ID zurück, welche innerhalb einer URL benutzt werden kann.
 */
export function idFuerRennen(rennen: RennenMitAblageort): string {
    return idFuerAblageort(rennen);
}

/**
 * Ermittelt den Ablageort des Rennens auf Basis der ID.
 */
export function ablageortAusRennId(id: string): Ablageort {
    return ablageortAusId(id);
}

function idFuerAblageort(ablageort: Ablageort): string {
    // Der Vereinsordner selbst darf nicht aus mehren Ordnern bestehen,
    // der relative Dateiname hingegen kann Unterordner beinhalten.
    return ablageort.vereinsordner +
        '+' +
        replaceSlashWithPlus(withoutJsonSuffix(ablageort.dateiname));
}

function ablageortAusId(id: string): Ablageort {
    const absolutePath = withJsonSuffix(replacePlusWithSlash(id));

    // z.B. [verein_1, 'veranstaltung_1', 'veranstaltung.json']
    const parts = absolutePath.split('/');

    return {
        vereinsordner: parts[0],
        dateiname: parts.slice(1).join('/')
    }
}

function withoutJsonSuffix(s: string): string {
    return s.slice(0, -5);  // .json -> 5 characters
}

function withJsonSuffix(s: string): string {
    return s + '.json';
}

function replaceSlashWithPlus(s: string): string {
    return s.replace(/\//g, '+')
}

function replacePlusWithSlash(s: string): string {
    return s.replace(/\+/g, '/')
}
