import React from "react";
import {Link} from "react-router-dom";
import {VeranstaltungMitAblageort} from "../model/routing";
import AppInfo from "../shared/AppInfo";
import Rennliste from "./Rennliste";

type VeranstaltungsmenuProps = {
    /** Veranstaltung für welche die Navigation aufgebaut wird. */
    veranstaltung: VeranstaltungMitAblageort
}

/**
 * Komponente welche das Menü einer Veranstaltung darstellt.
 */
function Veranstaltungsmenu(props: VeranstaltungsmenuProps) {

    const rennenMitAblageort = props.veranstaltung.rennen.map(r => {
        return {...r, vereinsordner: props.veranstaltung.vereinsordner}
    });

    return (
        <nav>
            {/* Margin m-2 wird auch auf der Veranstaltungsuebersichtsseite genutzt, die AppInfo "springt" somit nicht. */}
            <div className="m-2">
                <AppInfo/>
            </div>

            <Link to="/">
                <i className="las la-arrow-circle-left la-lg mx-2"/>
                zur Veranstaltungsübersicht
            </Link>

            <Rennliste rennen={rennenMitAblageort}/>

        </nav>
    );
}

export default Veranstaltungsmenu;
